<template>
	<div>
		<div style="margin: 10px 0">
			<el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" clearable v-model="name">
			</el-input>
			<el-input style="width: 200px" placeholder="请输入标的编号" suffix-icon="el-icon-search" clearable v-model="bdid">
			</el-input>
			<el-select v-model="istrue" placeholder="是否中标">
				<el-option label="是" value="是"></el-option>
				<el-option label="否" value="否"></el-option>
			</el-select>
			<div>
				日期范围:
				<el-date-picker type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="effectiveStartTime"></el-date-picker>至
				<el-date-picker placeholder="选择日期" value-format="yyyy-MM-dd" v-model="effectiveEntTime"></el-date-picker>
			</div>
			<el-button class="ml-5" type="primary" @click="load">搜索</el-button>
			<el-button type="warning" @click="reset">重置</el-button>
		</div>

		<div style="margin: 10px 0">

			<el-button type="primary" @click="exportToExcel" class="ml-5">导出 <i class="el-icon-top"></i></el-button>
		</div>

		<el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"
			@selection-change="handleSelectionChange">
			<el-table-column prop="xh" label="编号" align="center">
				<template slot-scope="scop">
					{{scop.$index+1}}
				</template>
			</el-table-column>
			<el-table-column prop="bdid" label="标的编号"></el-table-column>
			<el-table-column prop="gsname" label="公司名称"></el-table-column>
			<el-table-column prop="zbtime" label="招标时间" sortable></el-table-column>
			<el-table-column prop="biaodiname" label="标的名称"></el-table-column>
			<el-table-column prop="start" label="起点"></el-table-column>
			<el-table-column prop="end" label="终点"></el-table-column>
			<el-table-column prop="pname" label="标的物"></el-table-column>
			<el-table-column prop="state" label="标的状态"></el-table-column>
			<el-table-column prop="zbshuliang" label="招标数量"></el-table-column>
			<el-table-column prop="remark" label="应标数量"></el-table-column>
			<el-table-column prop="zbnum" label="中标数量"></el-table-column>
			<el-table-column prop="baojia" label="报价"></el-table-column>
			<el-table-column style="display: none;" prop="istrue" label="是否中标"></el-table-column>
			<el-table-column label="操作" width="160" align="center">
				<template slot-scope="scope">
					<el-button type="success" plain @click="handelCopyLink(scope.row.gsname)">复制公司名称</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="padding: 10px 0">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageNum"
				:page-sizes="[15,20,25,30,50,100,1000,10000]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</div>

		<el-dialog title="信息" :visible.sync="dialogFormVisible" width="30%">
			<el-form label-width="80px" size="small">
				<el-form-item label="公司名称">
					<el-input v-model="form.gsname" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="标的名称">
					<el-input v-model="form.biaodiname" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item label="标的状态">
					<el-input v-model="form.state" autocomplete="off"></el-input>
				</el-form-item>

			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "Biaodigl1",
		data() {
			return {
				tableData: [],
				newtableData: [],
				effectiveStartTime: "",
				effectiveEntTime: "",
				total: 0,
				pageNum: 1,
				pageSize: 10,
				name: "",
				state: "",
				istrue: "",
				isfirst: "1",
				bdid: "",
				form: {},
				dialogFormVisible: false,
				multipleSelection: [],
				user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
			}
		},
		created() {
			this.load()
		},
		methods: {
			load() {
				// console.log(this.effectiveStartTime)
				// this.newtableData = []
				this.request.get("/biaodigl/page1", {
					params: {
						pageNum: this.pageNum,
						pageSize: this.pageSize,
						name: this.name,
						state: this.state,
						istrue: this.istrue,
						bdid: this.bdid,
						starttime: this.effectiveStartTime,
						endtime: this.effectiveEntTime,
						isfirst: this.isfirst
					}
				}).then(res => {
					this.tableData = res.data.records
					this.total = res.data.total
					console.log(this.tableData)
				})
			},
			save() {
				this.request.post("/biaodigl", this.form).then(res => {
					if (res.code === '200') {
						this.$message.success("保存成功")
						this.dialogFormVisible = false
						this.load()
					} else {
						this.$message.error("保存失败")
					}
				})
			},
			handleAdd() {
				this.dialogFormVisible = true
				this.form = {}
				this.$nextTick(() => {
					if (this.$refs.img) {
						this.$refs.img.clearFiles();
					}
					if (this.$refs.file) {
						this.$refs.file.clearFiles();
					}
				})
			},
			handleEdit(row) {
				this.form = JSON.parse(JSON.stringify(row))
				this.dialogFormVisible = true
				this.$nextTick(() => {
					if (this.$refs.img) {
						this.$refs.img.clearFiles();
					}
					if (this.$refs.file) {
						this.$refs.file.clearFiles();
					}
				})
			},
			handleSelectionChange(val) {
				console.log(val)
				this.multipleSelection = val
			},
			delBatch() {
				let ids = this.multipleSelection.map(v => v.id) // [{}, {}, {}] => [1,2,3]
				this.request.post("/biaodigl/del/batch", ids).then(res => {
					if (res.code === '200') {
						this.$message.success("批量删除成功")
						this.load()
					} else {
						this.$message.error("批量删除失败")
					}
				})
			},
			reset() {
				this.name = ""
				this.istrue = ""
				this.state = ""
				this.effectiveStartTime = ""
				this.effectiveEntTime = ""
				this.isfirst = ""
				this.load()
			},
			handleSizeChange(pageSize) {
				console.log(pageSize)
				this.pageSize = pageSize
				this.load()
			},
			handleCurrentChange(pageNum) {
				console.log(pageNum)
				this.pageNum = pageNum
				this.load()
			},
			handleFileUploadSuccess(res) {
				this.form.file = res
			},
			handleImgUploadSuccess(res) {
				this.form.img = res
			},
			download(url) {
				window.open(url)
			},
			exp() {
				window.open("http://localhost:9090/biaodigl/export")
			},
			handleExcelImportSuccess() {
				this.$message.success("导入成功")
				this.load()
			},
			changeState(obj) {
				this.entity = obj;
				this.save();
			},
			focusChange() {
				this.timeFlag = ''
			},
			blurChange() {
				this.timeFlag = '2'
			},
			curChange(id) {
				this.pg = id
				this.getlists()
			},
			getlists() {
				load()
			}, // 导出数据
			exportToExcel() {

				var datas = []
				if (this.tableData.length === 0) {
					this.$message.warning('没有数据可导出');
					return;
				} else {
					for (var i = 0; i < this.tableData.length; i++) {
						let data = {
							xh: i + 1,
							bdid: this.tableData[i].bdid,
							gsname: this.tableData[i].gsname,
							biaodiname: this.tableData[i].biaodiname,
							start: this.tableData[i].start,
							end: this.tableData[i].end,
							pname: this.tableData[i].pname,
							baojia: this.tableData[i].baojia,
							remark: this.tableData[i].remark,
							istrue: this.tableData[i].istrue,
							danwei: this.tableData[i].danwei,
							zbtime: this.tableData[i].zbtime,
							zbnum:this.tableData[i].zbnum,
							zbshuliang:this.tableData[i].zbshuliang
						};
						datas.push(data);
					}
					this.newtableData = datas
					this.handelExcel()
				}
			},
			handelExcel() {
				require.ensure([], () => {
					let index = 0
					const {
						export_json_to_excel
					} = require('@/vendor/Export2Excel.js') //注意这个Export2Excel路径
					const tHeader = ['序号','标的编号','报价单位', '招标时间',  '标的时段/计划编号', '标的物', '起点', '终点', '计量单位',  '报价(元)','招标数量(车)',
						'应标数量(车)', '是否中标','中标数量(车)'
					] // 上面设置Excel的表格第一行的标题
					const filterVal = ['xh', 'bdid','gsname', 'zbtime', 'biaodiname', 'pname', 'start', 'end', 'danwei',
						 'baojia', 'zbshuliang','remark', 'istrue','zbnum'
					] // tableData里对象的属性key值
					const list = this.newtableData //把要导出的数据tableData存到list
					const data = this.formatJson(filterVal, list)
					export_json_to_excel(tHeader, data, '标的信息' + Date.now())
				})
			},
			//格式转换,不需要改动
			formatJson(filterVal, jsonData) {
				return jsonData.map(v => filterVal.map(j => v[j]))
			},
			handelCopyLink(val) {
				this.link = val;
				let that = this;
				this.$copyText(this.link).then(
					function(e) {
						that.$notify({
							title: "复制成功",
							type: "success",
						});
					},
					function(e) {
						that.$notify({
							title: "复制失败",
							type: "error",
						});
					}
				);
			},

		}
	}
</script>


<style>
	.headerBg {
		background: #eee !important;
	}
</style>
